<template>
  <div class="fourohfour content">

    <p class="lores s21">System Error</p>

    <p class="lores s12">
      404 NOT FOUND<br>
      The server cannot find the requested resource. Unrecognized endpoint, or invalid resource.<br>
    </p>

    <div class="spaced-links">
      <p class="lores s12">You might be looking for _______?<br>
      <router-link class="lores s12" to="/">liara.io</router-link>
      <router-link class="lores s12" to="/work">/work</router-link>
      <router-link class="lores s12" to="/bruch">/bruch</router-link>
      <router-link class="lores s12" to="/hack">/hack</router-link>
      </p>
    </div>

    <p class="lores s12">
      TECHNICAL INFORMATION<br>
      Endpoint <Redacted :secret="slug" /> not found<br>
      {{hexify(`404 ${slug}`)}}<br>
    </p>

  </div>
</template>

<script>
import Redacted from '@/components/Redacted.vue'

export default {
  name: 'Deadname',
  props: {
    pronouns: String,
    pronounsPage: String
  },
  components: {
    Redacted
  },
  data: function() {
    return {
      slug: window.location.href.replace(window.location.origin,'')
    }
  },
  methods: {
    hexify: function(string) {
      let output = ""
      for (let i = 0; i < string.length; i++) {
        output += string.substr(i,1).charCodeAt(0).toString(16) + " "
      }
      return output.toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.spaced-links {
  margin: 0 0 4rem 0;
}

.spaced-links a {
  display: inline-block;
}

.spaced-links a:not(:last-of-type) {
  margin-right: 1rem;
}

@media only screen and (max-width: 720px) {
  .spaced-links a {
    margin-right: 0 !important;
    display: block;
  }
}
</style>
